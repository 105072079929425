<template>
  <v-dialog
    v-model="dialog"
    max-width="1600px"
    max-height="auto"
    @click:outside="onClose"
  >
    <v-card flat>
      <v-app-bar absolute flat color="white">
        <v-toolbar color="primary" dark class="mt-7">
          <v-toolbar-title
            ><span
              >Quote Details: {{ quote.project_name }}</span
            ></v-toolbar-title
          >
          <v-spacer />
          <QuoteDetailsDate
            :created="new Date(quote.created).toLocaleDateString()"
            :modified="new Date(quote.modified).toLocaleDateString()"
          />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="close-button">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-app-bar>
      <v-sheet id="scroll-body" class="scroller">
        <v-container fluid class="mt-16">
          <v-toolbar dense flat>
            <v-row>
              <v-col cols="12" md="8" class="d-flex align-center subtitle-1">
                <span class="primary--text">
                  Quote Status:
                  <span class="text-capitalize font-weight-bold ml-2">{{
                    quote.status
                  }}</span>
                </span>
              </v-col>
            </v-row>
          </v-toolbar>
          <!-- <v-toolbar dense flat> -->
          <v-row dense class="px-1">
            <v-col cols="12" md="3" class="d-flex subtitle-1">
              <v-sheet
                outlined
                tile
                width="500"
                height="30"
                class="text-center pa-1"
              >
                <span class="primary--text">
                  {{
                    Object.keys(this.quote).length > 0
                      ? mappedQuote.user_details.username
                      : ""
                  }}
                </span>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center subtitle-1">
              <v-sheet
                outlined
                tile
                width="500"
                height="30"
                class="text-center pa-1"
              >
                <span class="primary--text">
                  {{
                    Object.keys(this.quote).length > 0
                      ? mappedQuote.user_details.full_name
                      : ""
                  }}
                </span>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center subtitle-1">
              <v-sheet
                outlined
                tile
                width="500"
                height="30"
                class="text-center pa-1"
              >
                <span class="primary--text">
                  {{
                    Object.keys(this.quote).length > 0
                      ? mappedQuote.user_details.email
                      : ""
                  }}
                </span>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center subtitle-1">
              <v-sheet
                outlined
                tile
                width="500"
                height="30"
                class="text-center pa-1"
              >
                <span class="primary--text">
                  {{
                    Object.keys(this.quote).length > 0
                      ? mappedQuote.company
                        ? mappedQuote.company_details.name
                        : mappedQuote.user_details.company_request
                      : ""
                  }}
                </span>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              lg="6"
              class="d-flex flex-column space-between px-2"
            >
              <QuoteSetupDiv :quote="mappedQuote" data-test="quote-setup-div" />
            </v-col>
            <v-col cols="12" lg="6" class="px-2">
              <FixtureDetailsDiv
                :quote="mappedQuote"
                data-test="fixture-details-div"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              :lg="dutDetails.length > 1 ? '6' : '12'"
              v-for="(dut, index) in mappedDutDetails"
              :key="index"
              class="mb-5"
            >
              <DutDetailsCard
                :dut="dut"
                :quoteName="quote.project_name"
                :dutConfigs="
                  dutConfigs.length > index ? dutConfigs[index] : null
                "
                :projectId="projectId"
                :calledFromConfigure="calledFromConfigure"
                @downloadFile="onDownloadFile"
                @onGoToFixtureDesignTable="gotoFixtureDesignTable"
                data-test="dut-details-card"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions v-if="!calledFromConfigure">
          <v-row class="mx-5 mb-5">
            <v-col cols="12" md="4">
              <v-btn
                x-large
                block
                color="secondary"
                @click="
                  onUpdateQuote({
                    quoteId: mappedQuote.id,
                    activeTab: ACTIVE_TAB_TO_RFQ,
                    newTab: false,
                  })
                "
                @contextmenu="
                  activateMenu({
                    nextRoute: {
                      quoteId: mappedQuote.id,
                      activeTab: ACTIVE_TAB_TO_RFQ,
                      newTab: true,
                    },
                    nextAction: 'onUpdateQuote',
                    event: $event,
                  })
                "
                data-test="rfq-button"
                data-cy="`quote-details-modal-rfq-button"
                >Go to RFQ Submission View</v-btn
              >
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                v-if="isAdmin"
                x-large
                block
                color="warning"
                :disabled="!mappedQuote.fd_id"
                @click="unlinkQuoteToFD(mappedQuote.fd_id)"
                data-cy="`quote-details-modal-unlink-button"
                >Unlink Quote to Fixture Design</v-btn
              >
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                x-large
                block
                color="secondary"
                :disabled="!mappedQuote.fd_project_pk"
                @click="gotoFixtureDesign(mappedQuote.fd_project_pk)"
                data-test="fdmodal-button"
                data-cy="`quote-details-modal-fdmodal-button"
                >Display Fixture Design Details</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "QuoteDetailsModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    dutDetails: {
      type: Array,
      default: () => [],
    },
    projectType: {
      type: Array,
      default: () => [],
    },
    projectTimeline: {
      type: Array,
      default: () => [],
    },
    fixtureWiring: {
      type: Array,
      default: () => [],
    },
    dutConfigs: {
      type: Array,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    calledFromConfigure: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ACTIVE_TAB_TO_RFQ: 1,
    };
  },
  components: {
    QuoteSetupDiv: () => import("./QuoteSetupDiv.vue"),
    FixtureDetailsDiv: () => import("./FixtureDetailsDiv.vue"),
    DutDetailsCard: () => import("./DutDetailsCard.vue"),
    QuoteDetailsDate: () => import("./QuoteDetailsDate.vue"),
  },
  computed: {
    mappedQuote() {
      const typeFound = this.projectType.find(
        (ptype) => ptype.pk == this.quote.project_type
      );
      const timelineFound = this.projectTimeline.find(
        (ttype) => ttype.pk == this.quote.project_timeline
      );
      const wiringFound = this.fixtureWiring.find(
        (wiring) => wiring.pk == this.quote.fixture_wiring
      );
      return Object.assign(
        { ...this.quote },
        {
          project_type_name: typeFound ? typeFound.description : null,
          project_timeline_name: timelineFound
            ? timelineFound.description
            : null,
          project_wiring_name: wiringFound ? wiringFound.description : null,
          dut_names: this.quote.dut_details
            ? Object.values(this.quote.dut_details)
            : "",
          full_part_number: this.quote.fixture_design_details
            ? Object.values(this.quote.fixture_design_details)[0]
                ?.full_part_number
            : null,
          fd_project_name: this.quote.fixture_design_details
            ? Object.values(this.quote.fixture_design_details)[0]?.project_name
            : null,
          fd_project_pk: this.quote.fixture_design_details
            ? Object.values(this.quote.fixture_design_details)[0]?.project_pk
            : null,
          fd_id: this.quote.fixture_design_details
            ? Object.keys(this.quote.fixture_design_details)[0]
            : null,
        }
      );
    },
    mappedDutDetails() {
      return this.dutDetails.map((dut, index) => ({
        ...dut,
        design_files: [
          {
            name: "DUT File",
            url: dut.test_point_file,
          },
          {
            name: "STEP File",
            url: dut.step_file,
          },
          {
            name: "Schematic",
            url: dut.schematic_file,
          },
          {
            name: "Gerbers",
            url: dut.gerber_zip,
          },
          {
            name: "ODB++",
            url: dut.odb_zip,
          },
          {
            name: "IPC-2581",
            url: dut.ipc_zip,
          },
          {
            name: "Other Files",
            url: dut.other_file,
          },
        ],
        dut_id: this.quote.fixture_design_details
          ? Object.values(this.quote.fixture_design_details)[0]?.dut_configs[
              index
            ]
          : null,
      }));
    },
    projectId() {
      return this.quote.fixture_design_details
        ? Object.values(this.quote.fixture_design_details)[0]?.project_pk
        : null;
    },
  },
  methods: {
    onClose() {
      this.$emit("closeQuoteDetailsModal");
    },
    onDownloadFile(payload) {
      this.$emit("onDownloadFile", payload);
    },
    onUpdateQuote(payload) {
      this.onClose();
      this.$emit("gotoUpdateQuote", payload);
    },
    gotoFixtureDesign(payload) {
      this.$emit("goToFixtureDesign", payload);
    },
    activateMenu(payload) {
      this.$emit("onActivateMenu", payload);
    },
    unlinkQuoteToFD(id) {
      this.$emit("onUnlinkQuote", { id, quoteId: this.mappedQuote.id });
    },
    gotoFixtureDesignTable(payload) {
      this.$emit("onGoToFixtureDesignTable", payload);
    },
  },
};
</script>
<style scoped>
.scroller {
  height: auto;
  overflow-y: auto;
}
</style>
